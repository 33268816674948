import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { ApiInterceptor } from './utils/interceptors';
import { ErrorService } from './shared/services/error.service';
import { ApiModule, Configuration } from './utils/api';

export const appConfig: ApplicationConfig = {
    providers: [
        // angular / interceptors
        provideHttpClient(withInterceptors([ApiInterceptor])),
        // plugins
        importProvidersFrom(
            ApiModule.forRoot(() => {
                return new Configuration({ basePath: '/api' });
            })
        ),

        // error handler
        { provide: ErrorHandler, useClass: ErrorService }
    ]
};
