import { inject } from '@angular/core';
import { AppService } from '../shared/services/app.service';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { HttpErrorResponse, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

export const ApiInterceptor = (request: HttpRequest<any>, next: HttpHandlerFn) => {
    // injections
    const appService = inject(AppService);
    const activatedRoute = inject(ActivatedRoute);

    let clonedRequest = request.clone();

    if (clonedRequest.method == 'GET') {
        const version = `${appService.getVersionNumber()}`;
        clonedRequest = clonedRequest.clone({
            setParams: {
                v: version
            }
        });
    }

    return next(clonedRequest).pipe(
        catchError((error: HttpErrorResponse) => {
            if (checkNoNetworkConnection(error)) {
                alert('No network connection. Please try again later.');
                const error = new Error('No network connection');
                console.error(error);
            }
            let route = activatedRoute.snapshot;
            while (route.firstChild) {
                route = route.firstChild;
            }
            switch (error.status) {
                case 400:
                    // validation
                    break;
                case 409:
                    // bad request
                    break;
                case 401:
                    onUnauthorized(route);
                    break;
                case 500:
                case 502:
                case 403:
                case 429:
                case 404:
                    showFailWhale(error);
                    break;
            }
            return throwError(() => error);
        })
    );
};

const showFailWhale = (error) => {
    const msg = `${error.error}`;
    document.querySelector('body').innerHTML = msg;
};

const onUnauthorized = (route: ActivatedRouteSnapshot) => {
    while (route.firstChild) {
        route = route.firstChild;
    }
    if (!route.data['public']) {
    }
};

const checkNoNetworkConnection = (error: any): boolean => {
    return (
        error instanceof HttpErrorResponse &&
        !error.headers.keys().length &&
        !error.ok &&
        !error.status &&
        !error.error.loaded &&
        !error.error.total
    );
};
