import { ErrorHandler, Injectable, Injector, inject } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
    providedIn: 'root'
})
export class ErrorService extends ErrorHandler {
    // injections
    injector = inject(Injector);

    appInsights: ApplicationInsights;

    constructor() {
        super();
    }

    override handleError(error: Error) {
        this.logException(error);
        this.handleImportModuleErrors(error);
        if (!error?.message.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
            console.error(error);
        }
    }

    initMonitoring(instrumentationkey) {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: <string>instrumentationkey
            }
        });
        this.appInsights.loadAppInsights();
    }

    logPageView(name?: string, url?: string) {
        this.appInsights?.trackPageView({
            name: name,
            uri: url
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights?.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights?.trackMetric({ name: name, average: average }, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        if (
            !exception?.message ||
            exception?.message?.toLowerCase()?.includes('Http failure response'.toLowerCase()) ||
            exception?.message?.toLowerCase()?.includes('HttpErrorResponse'.toLowerCase()) ||
            exception?.message?.toLowerCase()?.includes('ExpressionChangedAfterItHasBeenCheckedError'.toLowerCase())
        ) {
            return;
        }

        this.appInsights?.trackException({
            exception: exception,
            severityLevel: severityLevel,
            properties: { location: window.location }
        });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.appInsights?.trackTrace({ message: message }, properties);
    }

    handleImportModuleErrors(error: Error) {
        const importModuleErrors: readonly string[] = [
            'Failed to fetch dynamically imported module',
            'Failed to load module script'
        ];
        if (importModuleErrors.some((err) => error.message.includes(err))) {
            setTimeout(() => {
                confirm('Application version outdated, please reload the page.');
                window.location.reload();
            }, 1);
        }
    }
}
